import React from 'react'
import {Box, Image, Button,Text,Link,Flex} from '@chakra-ui/react'
import {AppleStore,Playstore} from '../assetindex'
import bannerOne from '../assets/screenshots/iosFrame/image1.jpg'
import {IoLogoInstagram,IoLogoTwitter} from 'react-icons/io'
import {MdEmail} from 'react-icons/md'
import customTheme from '../extendTheme';
import { useInView } from "react-intersection-observer";
import SpareSVG from '../assets/logo/logo.svg';


const Banner = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  return (
    <Box   ref={ref}
    opacity={inView ? 1 : 0}
    transform={inView ? "translateY(0)" : "translateY(20px)"}
    transition="opacity 0.5s, transform 0.5s" pt='30px' pl={{base: '20px', md: '100px', xl: '100px'}} pr='20px'  >
   <header>
    <Box display='flex' alignItems='center' justifyContent='space-Between' ml={6} mr={6}>
    <Image src={SpareSVG} boxSize={120} />
      <Box display='flex' ml={6}>
      <Link href='https://instagram.com/fxlogapp' isExternal> <IoLogoInstagram color='#78cad3' size={20}   /></Link>
      <Box ml={6} />
      <Link href='https://twitter.com/fxlogapp' isExternal>  <IoLogoTwitter color='#78cad3' size={20}/></Link>
    <Box ml={6} />
    <Link href='mailto:admin@sparetz.com' isExternal>  <MdEmail  color='#78cad3' size={20}/></Link>
      </Box>
    </Box>
   </header>

    <Box >
    <Flex direction={{md:'row',lg:'row',sm:'column',base:'column' }}>
      <Flex direction='column'>

      <Text
                        fontWeight='bold'
                        fontSize='50px'
                        pb='46px'
                        mt='40px'
                        color='white'
                        display={{base: 'none', md: 'block', xl: 'block'}}
                    >
                        Nunua Au Lipia KidogoKidogo <br /> Spare ya Gari Lako
                    </Text>
                     <Text
                        fontWeight='bold'
                        fontSize='30px'
                        color='white'
                        mt='40px'
                        pb='26px'
                        display={{base: 'block', md: 'none', xl: 'none'}}
                    >
                  Nunua Au Lipia KidogoKidogo  Spare ya Gari Yako
                    </Text>
                    <Text  fontSize='20px'
                        color={customTheme.colors.whiteTwo[100]}>
                    Njia salama ya kununua spare ya gari lako bila kuingia kwenye madeni. 
                    </Text>
                    <Flex mt={5} justifyContent={{base: 'center', md: 'inherit', xl: 'inherit' , sm:'center'}}>
                                <Link href='https://play.google.com/store/apps/details?id=com.tukesolutions.sparetz' >
                                    <Image src={Playstore} />
                                </Link>
                                <Link href='https://apps.apple.com/app/sparetz/id6447182122' >
                                    <Image src={AppleStore}/>
                                </Link>
                             
                             {/* <Link href='https://shop.sparetz.com/'>
                                <Button px={9}  ml={3} backgroundColor='#E76F51'   color='white' variant='solid'>
    Web App
  </Button>
                             </Link> */}
                            </Flex>
                           
      </Flex>
      <Flex>

    <Image  width="container.md" src={bannerOne} alt="Brandlogo" /> 
      </Flex>
    </Flex>

    </Box>


  

  </Box>

  )
}

export default Banner

import './App.css';
import {Banner,WhyYou,Action} from './components/componentIndex'

function App() {
  return (
    <>
    
    <Banner />
  
    </>
  );
}

export default App;
